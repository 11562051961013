import React, { useContext, useEffect } from "react";
import { isBrowser } from "../../utils/articleUtil";
import * as queryString from "query-string";
import { useAuth } from "../../util/auth";
import { post } from "../../utils/api";
import auth0 from "../../util/auth0";
import AppContext from "../../AppContext";
import { navigate } from "gatsby";
import Layout from "../../components/layout";
import { CircularProgress } from "@mui/material";

const Direct = () => {
  return (
    <Layout hideHeader={true} hideFooter={true}>
      <main
        className="wrapper main-data-container"
        style={{ padding: 0 }}
        role="main"
        data-datocms-noindex
      >
        <DirectComp />
      </main>
    </Layout>
  );
};

export default Direct;

const DirectComp = () => {
  const auth = useAuth();
  const appContext = useContext(AppContext);
  const handleAuth = async (user) => {
    user.app = "SUDOKU";

    try {
      const userRes = await post(`users`, user, {
        token: auth0.extended.getToken(),
      });
      appContext.updateState("user", userRes);
      navigate("/games/sudoku");
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (isBrowser()) {
      const params = queryString.parse(window.location.search);
      if (!params) {
        navigate("/auth/signup");
        return;
      }
      const search = window.location.search.split("?")[1];
      const searchParams = JSON.parse(
        '{"' +
          decodeURI(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );

      if (searchParams.email && searchParams.name) {
        const password = process.env.GATSBY_TEMP_PASSWORD || "password123";
        auth
          ?.signup(searchParams.email, password, searchParams.name)
          .then((user) => {
            const usrId = user.sub.split("|")[1];
            // auth.sendPasswordResetEmail(searchParams.email);
            // Call auth complete handler
            dataLayer.push({
              event:'dl_push',
              interaction_type:'sign_up',
              signup_method:'magic_link',
              hostname: window.location.hostname,
              url: window.location.href,
              page_Path: window.location.pathname,
              page_title: document.title,
              user_id:usrId
           });
            handleAuth(user);
            return user;
          })
          .catch((e) => {
            if (e.message === "Invalid sign up") {
              navigate("/auth/signin");
            } else {
              navigate("/auth/signup");
            }
          });
      } else {
        navigate("/auth/signup");
      }
    }
  }, []);
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress size={50} />
    </div>
  );
};
